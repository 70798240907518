
  
  .wrps {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: url("../../assests/image/freepik.webp") no-repeat;
  
    background-size: cover;
    background-position: center;
  }
  
  .wrapper {
    width: 420px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.603);
    backdrop-filter: blur(9px);
    color: #fff;
    border-radius: 12px;
    padding: 30px 40px;
  }
  
  .wrapper h1 {
    font-size: 36px;
    text-align: center;
  }
  
  .wrapper .input-box {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 30px 0;
  }
  
  .input-box input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, 0.603);
    border-radius: 40px;
    font-size: 16px;
    color: #fff;
    padding: 20px 45px 20px 20px;
  }
  
  .input-box input::placeholder {
    color: #fff;
  }
  
  .input-box i {
    position: absolute;
    right: 20px;
    top: 30%;
    transform: translate(-50%);
    font-size: 20px;
  }
  
  .wrapper .remember-forgot {
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    margin: -15px 0 15px;
  }
  
  .remember-forgot label input {
    accent-color: #fff;
    margin-right: 3px;
  }
  
  .remember-forgot a {
    color: #fff;
    text-decoration: none;
  }
  
  .remember-forgot a:hover {
    text-decoration: underline;
  }
  
  .wrapper .btnlogin {
    width: 100%;
    height: 45px;
    border-radius: 40px;
    background-color: transparent;
    border:1px solid white ;
    color: white;
    outline: none;
    border: 2px solid rgba(255, 255, 255, 0.603);
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
  }

  
  .wrapper .register-link {
    font-size: 14.5px;
    text-align: center;
    margin: 20px 0 15px;
  }
  
  .register-link p a {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
  }
  
  .register-link p a:hover {
    text-decoration: underline;
  }
  



  