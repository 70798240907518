.sidebar {
  width: 250px;
  background-color: #ffffff;
  padding-top: 10px;
  position: relative;
  height: 100vh;
  top: 0;
  left: 0;
  /* z-index: 999; */
  border-right: 1px solid #dee2e6;
  display: flex;
  flex-direction: column;
}

.sidebar-menu {
  font-size: 1rem;
  list-style-type: none;
  padding: 0 12px 20px 8px;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 3;
}
.sidebar-menu.bottom {
  flex-grow: 0;
  margin-bottom: 40px;
}
.sidebar-menu li .nav-link {
  border-radius: 10px;
  padding: 16px 15px;
  text-decoration: none;
  display: flex;
  font-weight: 500;
  align-items: center;
}
.sidebar-menu li .nav-link.active {
  font-weight: 700;
}
.sidebar-menu li .nav-link.active img.icon {
  border: 2px solid white;
}
.sidebar-menu li .nav-link .icon {
  margin-right: 15px;
  width: 24px;
  height: 24px;
  transition: all 0.2s ease-in-out;
}

.rounded-circle {
  border-radius: 100%;
}
.stama {
  font-size: 25px;
  font-weight: 700;
  color: #7b2cbf;
}
.nav-link {
  display: flex;
  align-items: center;
  padding: 10px;
  color: white;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}
.nav-link.active {
  background-color: #7b2cbf;
  color: white !important;
}
.nav-link .icon {
  margin-right: 10px;
}


