@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  height: 100%;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  overflow: hidden;
}
.hidden {
display: none;
}
::-webkit-scrollbar{
  display: none;
}